import React from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import { useBooleanState, useIsMobile, useAlertMessage } from '@fingo/lib/hooks';
import EmbedYouTubeDialog from '@fingo/lib/components/embedYouTubeDialog.js/EmbedYoutubeDialog';
import { getHelpVideoLink } from '@fingo/lib/constants';
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import FingoAlert from '@fingo/lib/components/dialogs/alerts/FingoAlert';
import Close from '@mui/icons-material/Close';
import { KobrosAskForDemoButton } from '../buttons';

const KobrosVideoDialog = ({ video, open, setOpen, children }) => {
  const [openVideo, toggleOpenVideo] = useBooleanState();
  const isMobile = useIsMobile();
  const [alertMessage, setAlertMessage] = useAlertMessage();

  return (
    <>

      <Dialog open={open} PaperProps={{ borderRadius: '300px' }} onClose={() => setOpen(false)}>
        <FingoAlert alert={alertMessage} setAlert={setAlertMessage} />
        <Box
          paddingTop={5}
          paddingBottom={3}
          paddingX={{ xs: 5, md: 10 }}
          borderRadius={100}
        >
          {children}
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={1.5}
            alignItems="center"
            justifyContent="center"
            marginTop={2.5}
          >
            <KobrosAskForDemoButton setAlertMessage={setAlertMessage} text="Solicitar más info" />
            <LoadingButton
              variant="contained"
              color="secondary"
              size="small"
              sx={{ borderRadius: '20px', height: 30, fontWeight: 'normal', my: 1 }}
              onClick={() => toggleOpenVideo()}
              id="actions-kobros-button-video"
            >
              {!isMobile && <PlayCircleIcon sx={{ marginRight: 0.5 }} />}
              Ver tutorial
            </LoadingButton>
          </Stack>
        </Box>
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          sx={{
            position: 'absolute',
            right: 4,
            top: 4,
          }}
        >
          <Close />
        </IconButton>
      </Dialog>
      <EmbedYouTubeDialog
        id="kobros-action-tutorial"
        videoId={getHelpVideoLink(video)}
        openVideo={openVideo}
        toggleOpenVideo={toggleOpenVideo}
      />
    </>
  );
};

KobrosVideoDialog.propTypes = {
  video: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default KobrosVideoDialog;
