import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Close from '@mui/icons-material/Close';
import React from 'react';
import PropTypes from 'prop-types';

const FingoDialog = ({
  open,
  handleClose,
  title,
  children,
  dialogActionButton,
}) => (
  <Dialog
    open={open}
    onClose={handleClose}
    fullWidth
    classes={{ paper: { borderRadius: 16 } }}
  >
    <DialogTitle
      component="div"
      sx={{
        borderRadius: 4,
        bgcolor: 'primary',
        py: 3,
      }}
    >
      <Typography
        align="center"
        variant="h4"
      >
        {title}
      </Typography>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <Close sx={{ color: 'common.white' }} />
      </IconButton>
    </DialogTitle>
    <DialogContent sx={{ mt: 2, px: 6 }}>{children}</DialogContent>
    <DialogActions>{dialogActionButton}</DialogActions>
  </Dialog>
);

FingoDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  dialogActionButton: PropTypes.element,
};
FingoDialog.defaultProps = {
  dialogActionButton: null,
};

export default FingoDialog;
