import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import useGetCountryFromUrl from '@fingo/lib/hooks/useGetCountryFromUrl';
import { useSelectedCompany } from '@fingo/lib/hooks';
import ChileCessionsSummary from './ChileCessionsSummary';
import MexicoCessionsSummary from './MexicoCessionsSummary';

const CessionsSummaryProvider = ({ setCessionInvoices }) => {
  const country = useGetCountryFromUrl();
  const selectedCompany = useSelectedCompany();
  const CessionsSummary = useMemo(() => {
    if (!selectedCompany || !country) return () => null;
    const CessionsSummaryMapper = {
      Chile: ChileCessionsSummary,
      Mexico: MexicoCessionsSummary,
    };
    return CessionsSummaryMapper[country.name];
  }, [country, selectedCompany]);
  return <CessionsSummary setCessionInvoices={setCessionInvoices} />;
};

CessionsSummaryProvider.propTypes = {
  setCessionInvoices: PropTypes.func,
};

CessionsSummaryProvider.defaultProps = {
  setCessionInvoices: () => {},
};
export default CessionsSummaryProvider;
