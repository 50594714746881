import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import dayjs from '@fingo/lib/config/dayjs';
import { formatMoney } from '@fingo/lib/helpers/money';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import HelpOutline from '@mui/icons-material/HelpOutline';
import CreditCard from '@mui/icons-material/CreditCard';

const SKELETON_HEIGHT = 25;

const SummaryCard = ({
  title,
  amount,
  previousAmount,
  amountType,
  tooltipTitle,
  Icon,
  loading,
}) => {
  const handleMoney = useCallback((money) => `$ ${formatMoney(money)}`, []);
  const handleNumber = useCallback((number) => formatMoney(number), []);
  const handlers = {
    number: handleNumber,
    money: handleMoney,
  };
  return (
    <Card sx={{ height: '100%', p: 4 }}>
      <Stack direction="row" spacing={1} width="100%" alignItems="center">
        <Icon fontSize="medium" color="primary" />
        <Typography variant="h2" color="text.light">
          {title}
        </Typography>
        {tooltipTitle && (
          <Tooltip title={tooltipTitle} placement="right" arrow>
            <HelpOutline color="primary" sx={{ ml: 'auto !important' }} />
          </Tooltip>
        )}
      </Stack>
      <Stack alignItems="flex-end" mt={2} direction="row">
        {loading ? (
          <Skeleton height={SKELETON_HEIGHT} width="100%" />
        ) : (
          <>
            {previousAmount !== null && (
              <Typography variant="h6" color="text.light">
                {dayjs().format('MMM YY')}
              </Typography>
            )}
            <Tooltip title={handlers[amountType](amount)}>
              <Typography
                align="right"
                variant="h4"
                color="text.primary"
                ml="auto"
                noWrap
              >
                {handlers[amountType](amount)}
              </Typography>
            </Tooltip>
          </>
        )}
      </Stack>
      <Stack alignItems="flex-end" mt={2} direction="row">
        {loading ? (
          <Skeleton height={SKELETON_HEIGHT} width="100%" />
        ) : (
          <>
            {previousAmount !== null && (
              <>
                <Typography variant="h6" color="text.light">
                  {dayjs().subtract(1, 'months').format('MMM YY')}
                </Typography>
                <Tooltip title={handlers[amountType](previousAmount)}>
                  <Typography
                    align="right"
                    variant="h5"
                    color="text.light"
                    ml="auto"
                    noWrap
                  >
                    {handlers[amountType](previousAmount)}
                  </Typography>
                </Tooltip>
              </>
            )}
          </>
        )}
      </Stack>
    </Card>
  );
};

SummaryCard.propTypes = {
  title: PropTypes.string,
  amount: PropTypes.number.isRequired,
  previousAmount: PropTypes.number,
  amountType: PropTypes.oneOf(['number', 'money']),
  tooltipTitle: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  Icon: PropTypes.object,
  loading: PropTypes.bool.isRequired,
};

SummaryCard.defaultProps = {
  title: '',
  tooltipTitle: '',
  previousAmount: null,
  amountType: 'number',
  Icon: CreditCard,
};

export default SummaryCard;
