import React from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import PropTypes from 'prop-types';
import { PeopleHug } from '@fingo/lib/assets';

const PrimaryFormCard = ({ leftSideComponent, rightSideComponent }) => (
  <Card
    width="100%"
    sx={
        (theme) => ({
          px: 4,
          background: {
            xs: `linear-gradient(270deg, ${theme.palette.primary.main} 50%, transparent 50%)`,
            md: `linear-gradient(295deg, ${theme.palette.primary.main} 57%, transparent 57%)`,
          },
        })
      }
  >
    <Grid container justifyContent="space-between" height="100%">
      <Grid container item xs={5} md={4} height="auto" pt={8}>
        {leftSideComponent}
        <Icon
          sx={{
            height: 'auto', width: { xs: '100%', md: '100%' }, mt: 'auto',
          }}
        >
          <img src={PeopleHug} alt="people-hug" style={{ width: '100%' }} />
        </Icon>
      </Grid>
      <Grid container item xs={5} md={6} height="auto" pt={8} pb={6}>
        {rightSideComponent}
      </Grid>
    </Grid>
  </Card>
);

PrimaryFormCard.propTypes = {
  leftSideComponent: PropTypes.element.isRequired,
  rightSideComponent: PropTypes.element.isRequired,
};

export default PrimaryFormCard;
