import React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Badge from '@mui/material/Badge';
import PropTypes from 'prop-types';
import { useIsMobile } from '@fingo/lib/hooks';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

const HomeCard = ({ CardIcon, id, title, text, onClickHandler, count }) => {
  const isMobile = useIsMobile();
  const displayOffer = count > 0;

  return (
    <Badge
      sx={{
        height: { xs: '100%', md: 290, lg: 250 },
        width: '100%',
        flexShrink: 1,
        '& .MuiBadge-badge': {
          fontSize: 15,
          height: 30,
          width: 30,
          borderRadius: 20,
        },
      }}
      badgeContent={count}
      color="primary"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Paper
        id={id}
        onClick={() => { onClickHandler(); }}
        sx={(theme) => ({
          borderRadius: 4,
          height: '100%',
          width: '100%',
          border: displayOffer ? `3px solid ${theme.palette.primary.main}` : 'none',
          cursor: 'pointer',
          '&:hover': {
            boxShadow: '0px 15px 15px rgba(0, 0, 0, 0.5)',
            transform: 'scale(1.05)',
          },
          transition: 'all 0.15s ease-in-out',
          display: 'flex',
          flexDirection: isMobile ? 'row' : 'column',
          alignItems: isMobile ? 'center' : 'space-between',
          justifyContent: isMobile ? 'flex-start' : 'space-between',
          padding: isMobile ? 2 : 2.5,
          flexWrap: isMobile ? 'wrap' : 'nowrap',
        })}
      >
        <CardIcon
          fontSize="large"
          sx={{
            borderRadius: 10000,
            p: 1,
            backgroundColor: 'text.primary',
            color: 'gray.A100',
            alignSelf: 'center',
          }}
        />
        <Typography variant="h6" align={isMobile ? 'left' : 'center'} px={1} fontWeight={500} fontSize={{ s: 16 }}>
          {title}
        </Typography>
        <Typography variant="h6" align={isMobile ? 'left' : 'center'} p={1} pl={0} sx={{ width: '100%' }}>
          {text}
        </Typography>
        {isMobile && (
          <ArrowRightAltIcon
            fontSize="small"
            sx={{
              color: 'primary.main',
              position: 'absolute',
              bottom: 4,
              right: 4,
            }}
          />
        )}
      </Paper>
    </Badge>
  );
};
HomeCard.propTypes = {
  id: PropTypes.string.isRequired,
  CardIcon: PropTypes.shape({
    $$typeof: PropTypes.symbol.isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  count: PropTypes.number,
  onClickHandler: PropTypes.func,
};
HomeCard.defaultProps = {
  count: 0,
  onClickHandler: () => {},
};

export default HomeCard;
