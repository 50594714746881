import React from 'react';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import AlertTwoButtons from './AlertTwoButtons';

const useStyles = makeStyles({
  title: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  image: {
    height: '30px',
  },
});

const OrderingCredentialsConfirm = ({ open, onClose, onAccept }) => {
  const classes = useStyles();
  const moreInfoOne = `
  Para poder realizar operaciones de ordering de manera exitosa, necesitamos almacenar 
  tu certificado digital con su respectiva contraseña, con el fin de automatizar la ejecución de 
  nuestros procesos. Esto lo realizamos con el propósito de agilizar nuestro servicio y 
  ofrecerte una mejor experiencia utilizando nuestra plataforma.
  `;
  const moreInfoTwo = `
  Además, tu información será almacenada de manera segura en nuestros sistemas, utilizando para esto 
  técnicas avanzadas de criptografía y seguridad, garantizando así el buen uso y manejo de tu información.
  `;
  const title = `
  Almacenamiento de credenciales
  `;
  const titleComponent = (
    <div className={classes.title}>
      <Typography variant="h3" color="textPrimary" align="left">
        {title}
      </Typography>
    </div>
  );
  const messageComponent = (
    <>
      <Typography variant="body1" align="justify" color="textPrimary" paragraph>
        {moreInfoOne}
      </Typography>
      <Typography variant="body1" align="justify" color="textPrimary" paragraph>
        {moreInfoTwo}
      </Typography>
    </>
  );
  return (
    <AlertTwoButtons
      open={open}
      onClose={onClose}
      onAccept={onAccept}
      message={messageComponent}
      title={titleComponent}
      acceptMsg="Entendido"
      closeMsg="Volver atrás"
    />
  );
};

OrderingCredentialsConfirm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
};

export default OrderingCredentialsConfirm;
